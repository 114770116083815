import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from 'react-router-dom';

import './arrowBlockStyles.scss';

import { useToastAction } from '../../../../hooks/useToastAction';

import { CurrentDataContext } from '../../../../contexts';

import * as DeploymentApi from "../../../../api/deployment";

import { Button as CmpButton } from "../../../../components/Button";
import { Label as CmpLabel } from "../../../../components/Label";

const WizardStepKubernetesDeploy = props => {
  const history = useHistory();
  
  const {currentDataId, currentDataPayload} = useContext(CurrentDataContext);

  const deployAction = useToastAction();

  const [isDeploying, setIsDeploying] = useState(false);
  const [deploymentStatuses, setDeploymentStatuses] = useState({});
  const [deploymentResult, setDeploymentResult] = useState(null);
  const [deploymentError, setDeploymentError] = useState(null);
  const [stepInformation, setStepInformation] = useState(null);

  let lastStep = 1;
  const pollingTimeoutInSec = 600;

  useEffect(async () => {
    // console.log("WizardStepKubernetesDeploy", "props", props);

    if (!props.isActive || deploymentResult || isDeploying)
      return;

    //console.log("WizardStepKubernetesDeploy", "props", props);

    resetDeploymentStatuses();
    setDeploymentError(null);
    setIsDeploying(true);
    await handleDeploy(props.data);
    setIsDeploying(false);
  }, [props.isActive]);

  useEffect(async () => {
    if (deploymentError)
    {
      if (!!props.onDeployError)
        await props.onDeployError(props);
    }
  }, [deploymentError]);

  
  useEffect(async () => {
    if (deploymentResult)
    {
      deployAction.execute(async () => {
        await props.postDeploy(props, deploymentResult);
        setStatus(lastStep, TwArrowBlock.statuses.success);

        if (!!props.onBeforeContinue)
        {
          await props.onBeforeContinue(props);
        }

        props.nextStep();
      }, "Failed to create Interact settings after deployment");
    }
  }, [deploymentResult]);

  const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

  const handleDeploy = async (data) => {
    //console.log("handleDeploy", "data", data, "data.tplEnvironment.id", data.tplEnvironment?.id ?? "N/A", "data.customerId", data.customerId);

    // deployAction.execute(async () => {
    let deployId = null;
    let currStep = 1;

    try
    {
      setDeploymentResult(null);
      setStatus(0, TwArrowBlock.statuses.running)

      let deploymentName = `${data.serverIdentifier?.toLowerCase().replaceAll(" ", "-") || ""}${!!data.serverIdentifier ? "-" : ""}${data.deploymentName}`;
      
      let result = null;
      if (data.action === "update")
        result = await DeploymentApi.initUpdateDeployFromTemplate(data.tplEnvironment.id, {serverId: data.server.serverId, templateEnvironmentId: data.tplEnvironment.id, templateId: data.template.id});
      else
        result = await DeploymentApi.initDeployFromTemplate(data.template, data.currentEnvironmentGroupId, { deploymentName: deploymentName, serverId: data.tplServerId, templateEnvironmentId: data.tplEnvironment.id});

      if (result.hasError) {
        setDeploymentError(result.error);
        setStatus(0, TwArrowBlock.statuses.failed)
      }
      else
      {
        deployId = result.sessionResult?.sessionId;
        lastStep = result.totalSteps + 1;
      }

      //console.log("handleDeploy", "result", result, "deployId", deployId, "lastStep", lastStep);

      if (!!deployId)
      {
        await props.onDeploymentIdSet(props, deployId);

        setStatus(0, TwArrowBlock.statuses.success)
        for (currStep = 1; currStep < lastStep; ++currStep)
        {
          setStepInformation(null);

          setStatus(currStep, TwArrowBlock.statuses.running)
          let result = await DeploymentApi.executeDeployStep(currStep, deployId);
          if (result.pollingRequired === true)
          {
            setStepInformation(`This step migth take up to ${pollingTimeoutInSec} seconds...`);
            //console.log("handleDeploy - PollingRequired", "currStep", currStep, "result", result);
            result = await pollDeployStep(currStep, deployId)
          }

          //console.log("handleDeploy", "currStep", currStep, "result", result);

          if (result.hasError) {
            setStepInformation(null);
            setDeploymentError(result.error);
            setStatus(currStep, TwArrowBlock.statuses.failed);
            setStatus(lastStep, TwArrowBlock.statuses.failed);
            break;
          }
          setStatus(currStep, TwArrowBlock.statuses.success)

          //console.log("handleDeploy", "result.complete", result.complete);

          if (result.complete)
          {
            setDeploymentError(null);
            setStepInformation(null);
            setDeploymentResult(result);

            //console.log("handleDeploy", "result", result);

            return result;
          }
        }
      }
    } catch (err) {
      setDeploymentError(err?.customErrorMessage || err?.message || "");
      setStatus(currStep, TwArrowBlock.statuses.failed);
      setStatus(lastStep, TwArrowBlock.statuses.failed);
    }
    // }, props.data.action === "update" ? "Failed to update a deployment from template" : "Failed to create a deployment from template")
  }

  const pollDeployStep = async (step, deployId) => {

    for (let i = 0; i < pollingTimeoutInSec; ++i)
    {
      await sleep(1000);
      var pollRes = await DeploymentApi.pollDeployStep(step, deployId);
      if (pollRes.status === 1 || pollRes.status === 2) {
        //("pollDeployStep", "i", i, "pollRes", pollRes);
        return pollRes.result;
      }
    }

    //console.log("pollDeployStep - Returning error");
    return { hasError: true, error: "Polling timeout" };
  }

  const onBack = () => {
    if (!!props.onBeforeClose)
      props.onBeforeClose();
    if (currentDataId === "history" && !!currentDataPayload)
      history.push(currentDataPayload);
  }

  const resetDeploymentStatuses = () => {
    setDeploymentStatuses({0: TwArrowBlock.statuses.pending,
      1: TwArrowBlock.statuses.pending, 
      2: TwArrowBlock.statuses.pending, 
      3: TwArrowBlock.statuses.pending, 
      4: TwArrowBlock.statuses.pending});
  }

  const setStatus = (id, status) => {
    setDeploymentStatuses(prevState => ({
      ...prevState,
      [id]: status
    }));
  }

  return (
    <>
      <div className='mb-6 mt-12 mb-12 flex'>
        <div className='flex-1' />
        <div>
          <TwArrowBlockContainer additionalClass=''>
            <TwArrowBlock status={deploymentStatuses['0']} text="Initializing template"/>
            <TwArrowBlock status={deploymentStatuses['1']} text={props.data.action === "update" ? "Updating storage" : "Creating storage"}/>
            <TwArrowBlock status={deploymentStatuses['2']} text={props.data.action === "update" ? "Updating Kubernetes" : "Deploy to Kubernetes"} />
            <TwArrowBlock status={deploymentStatuses['3']} text="Initiating Pages server"/>
            <TwArrowBlock status={deploymentStatuses['4']} text={props.data.action === "update" ? "Update complete" : "Deployment complete" }/>
          </TwArrowBlockContainer>
          {stepInformation && <CmpLabel text={stepInformation} type={CmpLabel.types.info} additionalClass='mt-8'/>}
        </div>
        <div className='flex-1' />
      </div>

      {deploymentError && (
        <>
          <CmpLabel text={deploymentError} type={CmpLabel.types.error} additionalClass='mt-8'/>

          <div className="flex mt-8">
            <div className="flex-1" />
              <CmpButton
                variant={CmpButton.variants.primary}
                className='w-40 mr-2'
                // disabled={!hasCrudAccess}
                onClick={onBack}
              >
                Back
              </CmpButton>
            <div className="flex-1" />
          </div>
        </>
      )}
    </>
  );
}

const TwArrowBlockContainer = ({ children, additionalClass = '', ...props }) => {
  return (
    <div className="blockArrowContainer" {...props}>
      <ul className={`blockArrowUl`}>
        {children}
      </ul>
    </div>
  );
}

const STATUSES = {
  pending: 'pendingStatus',
  running: 'runningStatus',
  success: 'successStatus',
  failed: 'failedStatus'
};

const TwArrowBlock = ({ children, text, status, additionalClass = '', ...props }) => {
  return (
    <li className={`${status} ${additionalClass}`}>
      {text && (text)}
      {!text && children && (children)}
    </li>
  );
}
TwArrowBlock.statuses = STATUSES;

export default WizardStepKubernetesDeploy;