import { useFormikContext } from 'formik'
import React, { useEffect } from 'react'
import { Select } from '../../../components/Select';
import { Toggle } from '../../../components/Toggle';
import { Label } from '../../../components/Label';
import { Checkbox } from '../../../components/Checkbox';
import { Textbox } from '../../../components/Textbox';
import { Password } from '../../../components/Password';
import { Button } from '../../../components/Button';
import { Number } from '../../../components/Number';
import { RadioButton } from '../../../components/RadioButton';
import { TwFormEx } from '../../../components/TailwindForm';
//import { expm1 } from 'core-js/es6/number';

export default function WebeditorSettingsForm({ languages, retentionPolicies }) {
    const { values, handleChange, errors, setFieldValue } = useFormikContext();

    const allColorDepths = [
        { name: "Black and White (1 bit)", value: 1 },
        { name: "Color, RGB (24 bit)", value: 24 },
    ];

    const viewpointArchiveOptions = [
        { name: 'Viewpoint', value: 0 },
        { name: 'On-prem', value: 1 },
    ]

    const environmentScopes = [
        { name: 'Select environment scope', value: '' },
        { name: 'Development', value: 'webeditor.dev' },
        { name: 'Test', value: 'webeditor.test' },
        { name: 'Stage', value: 'webeditor.stage' },
        { name: 'Demo', value: 'webeditor.demo' },
        { name: 'Production', value: 'webeditor.prod' },
    ]

    useEffect(() => {
        //console.log("WebeditorSettingsForm", "errors", errors);
    }, [errors])

    // useEffect(() => {
    //     console.log("WebeditorSettingsForm", "values?.webEditor?.mfdxClientSecret", values?.webEditor?.mfdxClientSecret);
    //     console.log("WebeditorSettingsForm", "values", values);
    // }, [])


    const handlePasswordChange = (e) => {

        //console.log("WebeditorSettingsForm", "handlePasswordChange", "e.target.name", e.target.name, "e.target.value", e.target.value);
        handleChange(e);
        if (e.target.name === "webEditor.mfdxClientSecret.value" && !!e.target.value) {
            //console.log("WebeditorSettingsForm", "handlePasswordChange", "webEditor.mfdxClientSecret.valueChanged", true);
            setFieldValue("webEditor.mfdxClientSecret.valueChanged", true);
            // handleChange(ex);
        }
    }

    const dummyPassword = "•••";

    return (
        <div className='space-y-4'>
            <TwFormEx.Section label={'Retention Policies'}>
                <Select
                    className={"px-0"}
                    name="webEditor.retentionPolicyId"
                    value={values?.webEditor?.retentionPolicyId}
                    label={"Retention Policies (optional)"}
                    onChange={e => handleChange(e)}
                    options={retentionPolicies}
                />
            </TwFormEx.Section>
            <TwFormEx.Section label={'Preferred Language'}>
                <Select
                    className={"px-0"}
                    name="webEditor.languageCode"
                    value={values?.webEditor?.languageCode}
                    onChange={e => handleChange(e)}
                    options={languages}
                    error={errors?.webEditor?.languageCode}
                />
            </TwFormEx.Section>
            <TwFormEx.Section label={'Security'}>
                <Select
                    className={"px-0"}
                    name="webEditor.environmentScope"
                    value={values?.webEditor?.environmentScope}
                    label={"Environment scope (optional)"}
                    onChange={e => handleChange(e)}
                    options={environmentScopes}
                />
                <p className="text-sm font-medium text-gray-900 dark:text-gray-100 mb-4 sm:mt-px sm:pt-2">Scope: {values?.webEditor?.environmentScope}</p>

                <Toggle
                    label={"Require JWT Authentication"}
                    value={values?.webEditor?.requireJwtAuthentication || false}
                    onChange={value => {
                        handleChange({ target: { name: 'webEditor.requireJwtAuthentication', value: value} });
                        if (value) {
                            handleChange({ target: { name: 'webEditor.requireEditorAuth', value: !value} });
                        }
                    }}
                />

                <Toggle
                    label={"Require Editor Authentication"}
                    value={values?.webEditor?.requireEditorAuth || false}
                    onChange={value => {
                        handleChange({ target: { name: 'webEditor.requireEditorAuth', value: value} });
                        if (value) {
                            handleChange({ target: { name: 'webEditor.requireJwtAuthentication', value: !value} });
                        }
                    }}
                />
                {
                    values?.webEditor?.requireEditorAuth && (
                        <Textbox prefix={'@'} label={'Required User Domain'}
                            onChange={e => handleChange(e)}
                            name={'webEditor.requiredUserDomain'}
                            value={values?.webEditor?.requiredUserDomain || ''}
                            error={errors?.webEditor?.requiredUserDomain}
                        />
                    )
                }
            </TwFormEx.Section>
            <TwFormEx.Section label={'Visibility'}>
                <Toggle
                    label={"Show Text Library"}
                    value={values?.webEditor?.textLibraryEnabled}
                    onChange={value => handleChange({ target: { name: 'webEditor.textLibraryEnabled', value } })}
                />
                <Toggle
                    label={"Show Preview Html"}
                    value={values?.webEditor?.previewHtmlEnabled}
                    onChange={value => handleChange({ target: { name: 'webEditor.previewHtmlEnabled', value } })}
                />
            </TwFormEx.Section>
            <TwFormEx.Section label="PDF Attachment" separator={false}>
                <Toggle
                    label={"Enable"}
                    value={values?.webEditor?.pdfAttachmentEnabled}
                    onChange={value => handleChange({ target: { name: 'webEditor.pdfAttachmentEnabled', value } })}
                    error={errors?.webEditor?.pdfAttachmentEnabled}
                />
                {values?.webEditor?.pdfAttachmentEnabled && (
                    <>
                        <Select
                            className={"px-0"}
                            name="webEditor.pdfAttachmentColorDepth"
                            value={values?.webEditor?.pdfAttachmentColorDepth}
                            label={"Color Depth"}
                            onChange={e => handleChange(e)}
                            options={allColorDepths}
                        />
                        <Number
                            name="webEditor.pdfAttachmentDPI"
                            value={values?.webEditor?.pdfAttachmentDPI}
                            label={"DPI"}
                            min={72}
                            max={300}
                            step={1}
                            onChange={e => handleChange(e)}
                            error={errors.webEditor?.pdfAttachmentDPI}
                        />
                        <div className="my-2 w-full">
                            <Toggle
                                label={"Duplex"}
                                value={values?.webEditor?.pdfAttachmentDuplex}
                                onChange={value => handleChange({ target: { name: 'webEditor.pdfAttachmentDuplex', value } })}
                            />
                        </div>
                    </>
                )}
            </TwFormEx.Section>
            <TwFormEx.Section label={'Distribution'}>
                <Toggle
                    label={"Enable"}
                    value={values?.webEditor?.endFormEnabled}
                    onChange={value => handleChange({ target: { name: 'webEditor.endFormEnabled', value } })}
                />
                {values?.webEditor?.endFormEnabled && (
                    <>
                        <div className="pb-3">
                            <Label
                                as='h5'
                                text="Distribution - output"
                            />
                            {/* <h5 className="text-sm leading-6 font-medium text-gray-900">Distribution - output</h5> */}
                        </div>
                        <div className="px-4 sm:px-6 w-full space-y-3">
                            <div>
                                <Checkbox
                                    name="webEditor.centralDistributionEnabled"
                                    value={values?.webEditor?.centralDistributionEnabled}
                                    label={"Central distribution"}
                                    onChange={e => handleChange({ target: { name: e.target.name, value: e.target.checked } })}
                                    error={errors.webEditor?.centralDistributionEnabled}
                                />
                                {values?.webEditor?.centralDistributionEnabled &&
                                    <div className="space-y-1 pt-1 px-4">
                                        <Checkbox
                                            name="webEditor.centralDistributionKivraEnabled"
                                            value={values?.webEditor?.centralDistributionKivraEnabled}
                                            label={"Kivra"}
                                            onChange={e => handleChange({ target: { name: e.target.name, value: e.target.checked } })}
                                            error={errors.webEditor?.centralDistributionKivraEnabled}
                                        />
                                        <Checkbox
                                            name="webEditor.centralDistributionDigipostEnabled"
                                            value={values?.webEditor?.centralDistributionDigipostEnabled}
                                            label={"Digipost"}
                                            onChange={e => handleChange({ target: { name: e.target.name, value: e.target.checked } })}
                                            error={errors.webEditor?.centralDistributionDigipostEnabled}
                                        />
                                        <Checkbox
                                            name="webEditor.centralDistributionCentralprintEnabled"
                                            value={values?.webEditor?.centralDistributionCentralprintEnabled}
                                            label={"Central print"}
                                            disabled={true}
                                            onChange={e => handleChange({ target: { name: e.target.name, value: e.target.checked } })}
                                            error={errors.webEditor?.centralDistributionCentralprintEnabled}
                                        />
                                    </div>
                                }
                            </div>
                            <div>
                                <Checkbox
                                    name="webEditor.archiveOnlyEnabled"
                                    value={values?.webEditor?.archiveOnlyEnabled}
                                    label={"Viewpoint/Archive"}
                                    onChange={e => handleChange({ target: { name: e.target.name, value: e.target.checked } })}
                                    error={errors.webEditor?.archiveOnlyEnabled}
                                />
                                {values?.webEditor?.archiveOnlyEnabled &&
                                    <div className="space-y-4 px-10 pt-1 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                        {viewpointArchiveOptions.map((viewpointArchiveOption) => (
                                            <RadioButton
                                                id={viewpointArchiveOption.value}
                                                name="webEditor.archiveMethod"
                                                enabled={true}
                                                value={viewpointArchiveOption.value}
                                                label={viewpointArchiveOption.name}
                                                checked={viewpointArchiveOption.value === values?.webEditor?.archiveMethod}
                                                onClick={e => {
                                                    handleChange({ target: { name: e.target.name, value: +e.target.value } })
                                                }}
                                            />
                                        ))}
                                    </div>
                                }
                            </div>
                            <Checkbox
                                name="webEditor.localPrintEnabled"
                                value={values?.webEditor.localPrintEnabled}
                                label={"Local print"}
                                onChange={e => handleChange({ target: { name: e.target.name, value: e.target.checked } })}
                                error={errors.webEditor?.localPrintEnabled}
                            />
                            <Checkbox
                                name="webEditor.emailEnabled"
                                value={values?.webEditor.emailEnabled}
                                label={"Email"}
                                onChange={e => handleChange({ target: { name: e.target.name, value: e.target.checked } })}
                                error={errors.webEditor?.emailEnabled}
                            />
                        </div>
                    </>
                )}
            </TwFormEx.Section>
            <TwFormEx.Section label={"MFDX Client Secret"}>
                <Password
                    name="webEditor.mfdxClientSecret.value"
                    value={values?.webEditor?.mfdxClientSecret?.value}

                    onChange={e => {
                        handlePasswordChange(e)
                    }}
                    showEyeButton={values?.webEditor?.mfdxClientSecret?.valueChanged === true || false}
                    placeHolder={values?.webEditor?.mfdxClientSecret?.hasValue === true ? dummyPassword : ''}
                    error={errors?.webEditor?.mfdxClientSecret?.value}
                />
            </TwFormEx.Section>
        </div>
    )
}
